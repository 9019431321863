class IDBMethod {
  // 数据库连接
  db = null;
  dataBaseName = "";
  transactionName = "";
  constructor(dataBaseName, transactionName) {
    this.dataBaseName = dataBaseName;
    this.transactionName = transactionName;
    const indexedDB =
      window.indexedDB ||
      window.webkitIndexedDB ||
      window.mozIndexedDB ||
      window.msIndexedDB;

    const IDBRequest = indexedDB.open(dataBaseName, 1);

    IDBRequest.onsuccess = (e) => {
      this.db = e.target.result;
    };
    IDBRequest.onupgradeneeded = (e) => {
      const db = e.target.result;
      if (db.objectStoreNames.contains(this.transactionName)) {
        return;
      }
      db.createObjectStore(this.transactionName);
    };
  }
  getObjectStore(transactionName) {
    return this.db
      .transaction(transactionName, "readwrite")
      .objectStore(transactionName);
  }
  save(key, value) {
    return this.getObjectStore(this.transactionName).put(value, key);
  }
  get(key) {
    return this.getObjectStore(this.transactionName).get(key);
  }
  delete(key) {
    return this.getObjectStore(this.transactionName).delete(key);
  }
  clear() {
    return this.getObjectStore(this.transactionName).clear();
  }
}

export default IDBMethod;
