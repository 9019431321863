<template>
  <div class="suxue-bannerTop">
    <van-sticky>
      <div class="suxue-bannerTop_content flex-row flex-al-center">
        <div class="suxue-bannerTop_title flex-center" :class="titleClassName">
          <span v-if="!$slots.title">{{ localTitle }}</span>
          <slot v-else name="title"></slot>
        </div>
        <div class="bannerTop_icons_left flex-row flex-al-center">
          <div
            v-for="(item, index) in getRenderIcons(iconLeft, icons)"
            :key="index"
            class="bannerTop_icons"
            @click="iconClick(item)"
          >
            <img :src="item.img" />
          </div>
        </div>
        <div class="bannerTop_icons_right flex-row flex-al-center">
          <div v-if="showIconRight">
            <div
              v-for="(item, index) in getRenderIcons(iconRight, icons)"
              :key="index"
              class="bannerTop_icons"
              @click="iconClick(item)"
            >
              <img :src="item.img" />
            </div>
          </div>
          <div class="bannerTop_slot">
            <slot name="right"></slot>
          </div>
        </div>
      </div>
    </van-sticky>
    <van-overlay
      :show="shareShow"
      class-name="share_overlay"
      @click="shareShow = false"
    >
      <div class="shareContent">
        <div class="img">
          <img
            src="https://img.yzcdn.cn/upload_files/2020/12/23/FsjwOPWDJ8tSJ0508s5Z2AdytvIw.png"
            alt="arrow"
          />
        </div>
        <div class="text">点击“右上角”分享给好友</div>
        <div class="know flex-center">我知道了</div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
// import { share } from "@/plugin/wechatJSSdk";
// import BlankContain from '../BlankContain'
export default {
  name: "BannerTop",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    titleClassName: {
      type: String,
      default: "",
    },
    documentSync: {
      type: Boolean,
      default: false,
    },
    shareTemplate: {
      type: Object,
      default: () => ({
        title: document.title,
        desc: window.location.href,
        link: window.location.href,
      }),
    },
    defaultAction: {
      type: Boolean,
      default: true,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    iconLeft: {
      type: Array,
      default: () => ["back", "home"],
    },
    iconRight: {
      type: Array,
      default: () => ["share"],
    },
    showIconRight: {
      type: Boolean,
      default: true,
    },
    jiaZhangSuo: {
      type: Boolean,
      default: false,
    },
    showLockTip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localTitle: "",
      shareShow: false,
      icons: [
        {
          name: "back",
          img: "https://img.yzcdn.cn/upload_files/2020/12/01/FpuJnEzfCNBfaIr7bjLAxCfQXUSt.png",
        },
        {
          name: "home",
          img: "https://img.yzcdn.cn/upload_files/2020/12/01/FshqH4yprd-MhjqDdOSjkQyymquf.png",
        },
        {
          name: "share",
          img: "https://img.yzcdn.cn/upload_files/2020/12/01/FkDjOVM_TijhpLoejtZG0p_3otmE.png",
        },
      ],
    };
  },
  watch: {
    title: {
      handler: function (newval) {
        this.localTitle = newval;
      },
      immediate: true,
    },
    localTitle: {
      handler: function (newval) {
        this.$emit("update:title", newval);
        if (this.documentSync) {
          document.title = newval;
        }
      },
    },
  },
  mounted() {
    if (this.shareTemplate.link) {
      // share(this.shareTemplate);
    }
  },
  methods: {
    setTitle(title) {
      this.localTitle = title;
    },
    getRenderIcons(prop, defaultIcons) {
      return prop.map((i) => {
        const item = defaultIcons.find((s) => s.name === i);
        if (item) {
          return item;
        }
        return i;
      });
    },
    iconClick(item) {
      const name = typeof item === "string" ? item : item.name;
      if (this.defaultAction) {
        if (name === "back") {
          this.$router.back();
        }
        if (name === "home") {
          window.location.href = "/";
        }
        if (name === "share") {
          this.shareShow = true;
        }
      }

      this.$emit("onClick", name);
    },
  },
};
</script>
<style lang="scss">
.suxue-bannerTop {
  height: 40px;
  background-color: #fff;
  border-bottom: 0.5px solid #f5f5f5;
  .share_overlay {
    background: rgba(0, 0, 0, 0.75);
    z-index: 99;
  }
  .shareContent {
    position: relative;
    height: 100%;
    .img {
      text-align: right;
      img {
        display: inline;
        margin-right: 27px;
        width: 73px;
      }
    }

    .text {
      margin-top: 15px;
      padding-right: 27px;
      font-size: 18px;
      text-align: right;
      line-height: 27px;
      color: #ffffff;
    }
    .know {
      position: absolute;
      bottom: 72px;
      width: 290px;
      left: 50%;
      transform: translateX(-50%);
      height: 50px;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      background: linear-gradient(90deg, #ff8d76 0%, #ff6863 100%);
      border-radius: 25px;
    }
  }
}
.suxue-bannerTop_content {
  position: relative;
  height: 40px;
  background-color: #fff;
  border-bottom: 0.5px solid #f5f5f5;
  padding: 0 16px;
}
.suxue-bannerTop_title {
  max-width: 60%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  height: 100%;
  margin: 0 auto;
  color: #333333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bannerTop_icons {
}
.bannerTop_slot {
  margin-right: 20px;
}
.bannerTop_icons img {
  width: 30px;
  margin-right: 20px;
}
.suxue-bannerTop_icons {
}
.bannerTop_icons_left {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  margin-left: 16px;
}
.bannerTop_icons_right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
</style>
