<template>
  <div>
    <BannerTop :icon-left="['back']" :show-icon-right="false" title="" />
    <div class="studentInfo">
      <div class="editButton flex-center" v-if="!login" @click="changeEdit">
        {{ edit ? "取消编辑" : "编辑" }}
      </div>
      <div class="content" :class="{ loginContent: login }">
        <div class="form">
          <!--  学校 -->
          <div class="formItem flex-row flex-al-center">
            <div class="label">学校</div>
            <div class="formEle" :class="[edit ? 'canEdit' : 'noEdit']">
              <VantPopPicker
                @confirm="schoolIndexChange"
                :columns="schoolList.map((i) => i.name)"
                :disabled="!edit"
              >
                <input
                  placeholder="请选择学校"
                  disabled
                  v-model="(schoolList[schoolIndex] || {}).name"
                />
                <img
                  src="https://img01.yzcdn.cn/upload_files/2022/03/28/FgPuobr7m1dwSQC_UnTI_mJPACqz.png"
                />
              </VantPopPicker>
            </div>
          </div>
          <!--  年级 -->
          <div class="formItem flex-row flex-al-center">
            <div class="label">年级</div>
            <div :class="[edit ? 'canEdit' : 'noEdit']" class="formEle">
              <VantPopPicker
                @confirm="gradeIndexChange"
                :columns="gradeList.map((i) => i.chName)"
                :disabled="!edit"
              >
                <input placeholder="请选择年级" disabled v-model="showGrade" />
                <img
                  src="https://img01.yzcdn.cn/upload_files/2022/03/28/FgPuobr7m1dwSQC_UnTI_mJPACqz.png"
                />
              </VantPopPicker>
            </div>
          </div>
          <!--  学段 -->
          <!--        <div class="formItem flex-row flex-al-center">-->
          <!--          <div class="label">学段</div>-->
          <!--          <div :class="[edit ? 'canEdit' : 'noEdit']" class="formEle">-->
          <!--            <VantPopPicker-->
          <!--              @confirm="phaseIndexChange"-->
          <!--              :columns="phaseDic.filter(Boolean)"-->
          <!--              :disabled="!edit"-->
          <!--            >-->
          <!--              <input-->
          <!--                placeholder="请选择学段"-->
          <!--                disabled-->
          <!--                v-model="phaseDic[studentInfo.phase]"-->
          <!--              />-->
          <!--              <img-->
          <!--                src="https://img01.yzcdn.cn/upload_files/2022/03/28/FgPuobr7m1dwSQC_UnTI_mJPACqz.png"-->
          <!--              />-->
          <!--            </VantPopPicker>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--  入学年份 -->
          <!--        <div class="formItem flex-row flex-al-center">-->
          <!--          <div class="label">入学年份</div>-->
          <!--          <div :class="[edit ? 'canEdit' : 'noEdit']" class="formEle">-->
          <!--            <VantPopPicker-->
          <!--              @confirm="enterSchoolYearIndexChange"-->
          <!--              :columns="enterSchoolYearList"-->
          <!--              :disabled="!edit"-->
          <!--            >-->
          <!--              <input-->
          <!--                placeholder="请选择入学年份"-->
          <!--                disabled-->
          <!--                v-model="enterSchoolYearList[enterSchoolYearIndex]"-->
          <!--              />-->
          <!--              <img-->
          <!--                src="https://img01.yzcdn.cn/upload_files/2022/03/28/FgPuobr7m1dwSQC_UnTI_mJPACqz.png"-->
          <!--              />-->
          <!--            </VantPopPicker>-->
          <!--          </div>-->
          <!--        </div>-->
          <!--  班级 -->
          <div class="formItem flex-row flex-al-center">
            <div class="label">班号</div>
            <div
              :class="[edit ? 'canEdit' : 'noEdit']"
              class="formEle noPadding"
            >
              <input
                placeholder="请输入班号（例：1）"
                type="number"
                :disabled="!edit"
                v-model="studentInfo.classNumber"
              />
            </div>
          </div>
          <!--  学号 -->
          <div class="formItem flex-row flex-al-center">
            <div class="label">学号</div>
            <div
              :class="[edit ? 'canEdit' : 'noEdit']"
              class="formEle noPadding"
            >
              <input
                placeholder="请输入教师分配学号"
                type="number"
                :disabled="!edit"
                v-model="studentInfo.studentNumber"
              />
            </div>
          </div>
          <!--  姓名 -->
          <div class="formItem flex-row flex-al-center">
            <div class="label">姓名</div>
            <div
              :class="[edit ? 'canEdit' : 'noEdit']"
              class="formEle noPadding"
            >
              <input
                placeholder="请输入学生姓名"
                :disabled="!edit"
                v-model="studentInfo.name"
              />
            </div>
          </div>
          <!--  手机 -->
          <div class="formItem flex-row flex-al-center">
            <div class="label">手机</div>
            <div
              :class="[edit ? 'canEdit' : 'noEdit']"
              class="formEle noPadding"
            >
              <input
                placeholder="请输入手机号"
                :disabled="!edit"
                v-model="studentInfo.mobile"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="login || edit" class="submit flex-center" @click="submit">
        {{ login ? "登录" : "保存" }}
      </div>
    </div>
  </div>
</template>

<script>
import {
  getStudentInfo,
  list4Student,
  modifyStudentInfo,
} from "../../API/user";
import { phaseDic } from "@/project/smartHomeworkUserClient/common/dictionary";
import { Options, Vue } from "vue-class-component";
import { Dialog, Toast } from "vant";
import VantPopPicker from "@/components/VantPopPicker";
import { initZidian } from "@/project/smartHomeworkUserClient/common/bookName";
import BannerTop from "@/components/BannerTop";
import LocalData from "@/static/js/LocalData";
@Options({
  components: { BannerTop, VantPopPicker },
  data() {
    const nowYear = new Date().getFullYear();
    const eduAllYearsNum = 12;
    const yearList = new Array(eduAllYearsNum + 1)
      .fill(nowYear)
      .map((y, i) => y - eduAllYearsNum + i);
    return {
      login: false,
      edit: false,
      schoolList: [],
      schoolIndex: "",
      phaseDic,
      phaseIndex: "",
      enterSchoolYearIndex: "",
      enterSchoolYearList: yearList,
      gradeList: [],
      originData: {},
      studentInfo: {
        name: "",
        schoolId: "",
        grade: "",
        classNumber: "",
        studentNumber: "",
        mobile: "",
      },
    };
  },
  computed: {
    showGrade() {
      const obj =
        this.gradeList.find((i) => +this.studentInfo.grade === +i.value) || {};
      console.log(obj);
      return obj.chName || "";
    },
  },
  async mounted() {
    if (this.$route.query.login) {
      this.login = true;
      this.edit = true;
    }
    const zidian = await initZidian();
    console.log(zidian);
    this.gradeList = zidian.grade;
    list4Student().then((res) => {
      if (res.status === 200) {
        this.schoolList = res.data;
        this.initStudentInfo();
      }
    });
  },
  methods: {
    async initStudentInfo(data = {}) {
      let setData = data;
      if (Object.keys(setData).length < 1) {
        const res = await getStudentInfo();
        if (res.status === 200) {
          setData = res.data;
        }
      }
      this.originData = {
        ...setData,
      };
      this.schoolIndex = this.schoolList.findIndex(
        (i) => i.id === setData.schoolId
      );
      this.phaseIndex = setData.phase;
      this.enterSchoolYearIndex = this.enterSchoolYearList.findIndex(
        (i) => i === setData.enterSchoolYear
      );
      this.studentInfo = {
        ...this.studentInfo,
        ...setData,
      };
    },
    changeEdit() {
      this.edit = !this.edit;
      if (!this.edit) {
        this.initStudentInfo(this.originData);
      }
    },
    schoolIndexChange(value, index) {
      console.log(index);
      this.schoolIndex = index;
      this.studentInfo.schoolId = this.schoolList[this.schoolIndex].id;
    },
    gradeIndexChange(value, index) {
      this.studentInfo.grade = this.gradeList[index].value;
    },
    enterSchoolYearIndexChange(value, index) {
      this.enterSchoolYearIndex = index;
      this.studentInfo.enterSchoolYear =
        this.enterSchoolYearList[this.enterSchoolYearIndex];
      console.log(this.studentInfo);
    },
    getPhoneNumber(e) {
      console.log(e);
    },
    submit() {
      const toastText = {
        name: "请输入姓名",
        schoolId: "请选择学校",
        phase: "请选择学段",
        enterSchoolYear: "请选择入学年份",
        classNumber: "请输入班号",
        studentNumber: "请输入学号",
        mobile: "请获取手机号",
      };
      for (let key in this.studentInfo) {
        if (toastText[key] && !this.studentInfo[key]) {
          return Toast(toastText[key]);
        }
      }
      if (Number(this.studentInfo.classNumber) === 0) {
        return Toast("班号不可为0");
      }
      if (Number(this.studentInfo.studentNumber) === 0) {
        return Toast("学号不可为0");
      }
      if (
        Number(this.studentInfo.studentNumber) !==
        Number(this.originData.studentNumber)
      ) {
        // 修改学号时，提示
        Dialog.confirm({
          title: "提示",
          message:
            "<div class='dialogMes'><img src='https://img01.yzcdn.cn/upload_files/2022/05/13/FobsCS_4oSLVAvZI7hdr9zRbdSfr.png'/>您的学号一年仅可修改3次，如修改学号，历史数据将清空！</div>",
          cancelButtonColor: "#4593FF",
          cancelButtonText: "继续修改",
          confirmButtonColor: "#333333",
          confirmButtonText: "取消",
          allowHtml: true,
        })
          .then(() => {
            // on confirm
          })
          .catch(() => {
            // on cancel
            this.submitAjax();
          });
      } else {
        this.submitAjax();
      }
    },
    submitAjax() {
      /// 修改年级，重置错题册年级选择
      if (this.studentInfo.grade !== this.originData.grade) {
        LocalData.LSModal.save("wrongBookGradeName", this.showGrade);
        LocalData.LSModal.delete("wrongBookVolumeName");
      }
      modifyStudentInfo(this.studentInfo).then(async (res) => {
        if (res.status === 200) {
          await this.initStudentInfo();
          Toast("保存成功");
          setTimeout(() => {
            this.edit = false;
            if (this.login) {
              this.$router.back();
            }
          }, 2000);
        } else if (res.status === 520) {
          Dialog.alert({
            title: "提示",
            allowHtml: true,
            message:
              "<div class='dialogMes'><img src='https://img01.yzcdn.cn/upload_files/2022/05/13/FobsCS_4oSLVAvZI7hdr9zRbdSfr.png'/>学号修改次数已达上限，本年内不可修改！</div>",
          });
        }
      });
    },
  },
})
export default class smartHomeworkUserClientStudentInfo extends Vue {}
</script>

<style lang="scss">
.dialogMes {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 14px;
  color: #333333;
  img {
    width: 17px;
    height: 17px;
    margin-left: 10px;
    margin-right: -5px;
    margin-top: 1px;
  }
}
.studentInfo {
  height: 100vh;
  overflow: auto;
  background: linear-gradient(180deg, #ecf2f7 0%, rgba(233, 244, 254, 0) 100%);
}

.studentInfo {
  display: flex;
  flex-direction: column;
  align-items: center;

  .editButton {
    align-self: flex-end;
    margin: 10px 14px;
    height: 24px;
    padding: 0 11px;
    font-size: 12px;
    color: #4995ff;
    border-radius: 90px;
    border: 1px solid #4995ff;
  }

  .content {
    width: 347px;
    padding: 7px 20px 2px 20px;
    background: #ffffff;
    border-radius: 6px;

    &.loginContent {
      margin-top: 15px;
    }

    .formItem {
      height: 55px;
      border-bottom: 1px solid #f0f0f0;

      &:last-child {
        border-bottom: none;
      }

      .label {
        margin-right: 23px;
        font-size: 14px;
        color: #020202;
      }

      .formEle {
        position: relative;
        padding-right: 20px;
        flex-grow: 1;

        input {
          height: 100%;
          width: 100%;
          font-size: 14px;
          text-align: right;
          color: #666666;
        }

        img {
          position: absolute;
          width: 11px;
          height: 11px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &.noPadding {
          padding-right: 0;
        }

        &.noEdit {
          padding-right: 0;

          img {
            display: none;
          }
        }

        .getNumberBut {
          text-align: right;
          font-size: 14px;
          color: #c2c2c2;
        }

        .getNumberButShow {
          text-align: right;
          font-size: 14px;
          color: #020202;
        }
      }
    }
  }
  .submit {
    margin: 98px auto auto;
    width: 284px;
    height: 45px;
    font-size: 14px;
    color: #ffffff;
    background: linear-gradient(180deg, #5ea1ff 0%, #2882ff 100%);
    border-radius: 100px;
  }
}
</style>
